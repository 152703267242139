const EN = {
    shipping_cost: 'Shipping cost',
    method: 'Method',
    cost_pounds: 'Cost [£]',
    add_shipping_cost: 'Add shipping cost',
    no_data: 'There is no data',
    selected_products: 'Selected products',
    actions: 'Actions',
    edit: 'Edit',
    delete: 'Delete',
    add_product: 'Add product',
    product: 'Product',
    product_code: 'Product code',
    buy_price: 'Buy price',
    sell_price: 'Sell price',
    supplier: 'Supplier',
    save_changes: 'Save changes',
    close: 'Close',
    are_you_sure_delete_products: 'Are you sure you wish to delete below listed product/s',
    this_cannot_be_undone: 'This cannot be undone',
    delete_product: 'Delete product',
    cancel: 'Cancel',
    bulk_products_editor: 'Bulk products editor',
    selected_products_to_edit: 'Selected products to edit',
    no_change: 'No change',
    expected_return_rate: 'Expected Return Rate',
    number_days_for_contract: '# of days for contract',
    add_shipping_method: 'Add shipping method',
    set_new: 'Set new',
    increase_by_value: 'Increase by value',
    increase_by_percent: 'Increase by %',
    previously_was: 'previously was',
    add_bundles: 'Add bundle',
    bundle_name: 'Bundle name',
    text: 'Text',
    select_products: 'Select products',
    are_you_sure_delete_bundles: 'Are you sure you wish to delete below listed bundle/s',
    delete_bundle: 'Delete bundle',
    bundles: 'Bundles',
    bundle_name: 'Bundle name',
    products: 'Products'
}
  
export default EN