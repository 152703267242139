<template>
    <div id="shop-settings-app" class="m-3">
      <shop-tab />
    </div>
</template>

<script>
import shopTab from './components/tabs/Shop.vue'
export default {
    name: 'settings',
    components: {
      shopTab,
    }
}
</script>


<style>
.modal-backdrop {
  opacity: 0.5;
}
.modal.fade.show {
  opacity: 1
}
</style>