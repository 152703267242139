<template>
  <div>
        <div class="ajaxModalContainer_modal modal modal_fullBleed fade" data-modal-modifier="modal_fullBleed" tabindex="-1" role="dialog" aria-labelledby="modal-add-product-label"
              id="modal-add-product">
              <div class="modal_container modal-xl modal-dialog" role="document">
                    <a class="modal_close" data-dismiss="modal" aria-label="Close">
                          <i class="glyphicon glyphicon-remove"></i>
                    </a>
                    <div class="modal-content">
                        <div class="modal_body">
                              <div
                                    class="panel m_widthDouble m_heightDouble m_paddingThick m_headerImportant m_footerImportant">
                                    <div class="panel_header">
                                          <h1 class="modal-title text-center" id="modal-add-product-label">{{ $t('add_product') }}</h1>
                                    </div>
                                    <div class="panel_body">
                                          <multiselect
                                                id="product-select"
                                                :value="value"
                                                :options="filteredOptions"
                                                :multiple="true"
                                                track-by="id"
                                                label="label"
                                                :loading="loading"
                                                @input="selected"
                                                :hideSelected="false"
                                                :closeOnSelect="false"
                                                :preserveSearch="true"
                                                :internal-search="false"
                                                :limit="20"
                                                @search-change="search"></multiselect>
                                    </div>
                                    <div class="panel_footer">
                                          <div class="panel_footerLeft">
                                                <div class="btnGroup_container">
                                                      <div class="btnGroup">
                                                            <a type="button" class="btn btn-primary" :class="{disabled:!value.length}" :data-dismiss="value.length ? 'modal':''" @click="save">{{ $t('save_changes') }}</a>
                                                            <a type="button" class="btn btn-info"
                                                                  data-dismiss="modal">{{ $t('close') }}</a>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>

                              </div>

                        </div>
                    </div>
              </div>
        </div>
  </div>
</template>

<script>
export default {
      name: 'addProduct',
      props: {
            selectedvalue: {
                  type: Array,
                  required: false,
            }
      },
      data(){
            return {
                  name: 'Add products',
                  loading: false,
                  value: [],
                  options: []
            }
      },
      computed: {
            filteredOptions(){
                  const allProducts = this.options;
                  const filteredProducts = allProducts.filter(item => {
                        const index = this.selectedvalue.findIndex(selectedItem =>
                              selectedItem.productId == item.id
                        )
                        if(index === -1){
                              return item
                        }
                  })
                  this.$emit('available-products-counter', filteredProducts.length)
                  return filteredProducts
            }
      },
      methods: {
            selected(value) {
                  this.value = value
            },
            save(){
                  if(this.value.length){
                        this.$emit('products-added', this.value)
                  }
                  this.clearValue()
            },
            getProducts(query = '') {
                  let customer_id = document.getElementById('shop-settings').getAttribute('data-customerId')
                  let q = query ? '?q=' + query : ''
                  this.loading = true
                  this.axios.get(`/customers/${customer_id}/products${q}`).then((response) => {
                        this.options = response.data
                        this.loading = false
                  })
            },
            search(searchQuery) {
                  this.getProducts(searchQuery)
            },
            clearValue(){
                  setTimeout(()=>this.value = [], 1000)
            }
      },
      mounted() {
            this.getProducts()
      },
}
</script>

<style>
#modal-add-product .panel_body {
      overflow: initial;
}
</style>