<template>
  <div class="panel">
          <div id="" class="panel  m_widthDouble m_heightDouble m_paddingThick m_headerColumns ">
            <div class="panel_header d-flex justify-content-between">
              <div class="panel_headerLeft">
                  <h1>{{ $t('shipping_cost') }}</h1>
              </div>
                    <div class="panel_headerRight">
                        <a class="btn btn-primary fontSmall" :class="{'disabled': shippingValue.length, 'show-modal': !shippingValue.length}" data-target="#modal-shipping-method">+ {{ $t('add_shipping_cost') }}</a>
                    </div>
                </div>
                <div class="panel_body">
                    <div class="panel_bodyLeft">
                        <table v-if="shippingValue.length" class="table mts m_misymecTheme">
                            <thead>
                                <tr>
                                    <th>{{ $t('method') }}</th>
                                    <th>{{ $t('cost_pounds') }}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="product in shippingValue" :key="product.id">
                                    <td>{{ product.name }}</td>
                                    <td><input type="number" :data-id="product.id" data-type="sellPrice" :value="product.sellPrice" v-debounce="updateProduct" ></td>
                                    <td>
                                      <a class="btn btn-danger show-modal m_fontSmall pull-right mls delete-link-btn mrs fontSmall" data-target="#modal-delete-product" @click="deleteProducts = [product]">x</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p v-else>{{ $t('no_data') }}</p>
                    </div>
                </div>
                <div class="panel_footer">
                </div>
          </div>
          <div v-if="!loading_bundles" class="panel  m_widthDouble m_heightDouble m_paddingThick m_headerColumns ">
            <div class="panel_header d-flex justify-content-between">
                <div class="panel_headerLeft">
                    <h1>{{ $t('bundles') }}</h1>
                </div>
                <div class="panel_headerRight">
                    <a class="btn btn-primary fontSmall show-modal" data-target="#modal-add-bundle">{{ $t('add_bundles') }}</a>
                </div>
            </div>
            <div class="panel_body">
                <div class="panel_bodyLeft">
                    <table v-if="bundles.length" class="table mts m_misymecTheme">
                        <thead>
                            <tr>
                                <th>{{ $t('bundle_name') }}</th>
                                <th>{{ $t('products') }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="bundle in bundles" :key="bundle.id">
                                <td>{{ bundle.name }}</td>
                                <td>
                                    <ul class="bundle-products-list">
                                        <li v-for="product in bundle.products" :key="product.id" class="bundle-product-badge">{{ product.name }}</li>
                                    </ul>
                                </td>
                                <td>
                                    <a class="btn btn-danger show-modal m_fontSmall pull-right mls delete-link-btn mrs fontSmall" data-target="#modal-delete-bundle" @click="deleteBundles = [bundle]">x</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p v-else>{{ $t('no_data') }}</p>
                </div>
            </div>
            <div class="panel_footer">
        </div>
          </div>
            <div id="" class="panel  m_widthDouble m_heightDouble m_paddingThick m_headerColumns ">
                <div class="panel_header d-flex justify-content-between">
                    <div class="panel_headerLeft">
                        <h1>{{ $t('selected_products') }}</h1>
                    </div>
                    <div class="panel_headerRight">
                        <input class="form-control search" v-model="filters.all.value" :disabled="!productValue.length" placeholder="Search"/>
                        <div class="actions-wrapper" :class="{'disabled': !selectedRows.length}">
                            {{ $t('actions') }}:
                            <a class="btn btn-default fontSmall" data-target="#modal-edit-products" @click="dispatchAction('Edit')" :class="{'show-modal': selectedRows.length}">{{ $t('edit') }}</a>
                            <a class="btn btn-danger fontSmall" data-target="#modal-delete-product" @click="dispatchAction('Remove')" :class="{'show-modal': selectedRows.length}">{{ $t('delete') }}</a>
                        </div>
                        <a class="btn btn-primary fontSmall" data-target="#modal-add-product" :class="{'disabled': !availableProductsToAdd, 'show-modal': availableProductsToAdd}">+ {{ $t('add_product') }}</a>
                    </div>
                </div>
                <div class="panel_body">
                    <div class="panel_bodyLeft">
                        <v-table
                            ref="activetable"
                            class="table mts m_misymecTheme"
                            v-show="productValue.length"
                            :data="productValue"
                            selectionMode="multiple"
                            :filters="filters"
                            selectedClass="item-selected"
                            @selectionChanged="selectionUpdate($event)">
                            <thead slot="head">
                                <v-th sortKey="name" defaultSort="asc">{{ $t('product') }}</v-th>
                                <v-th sortKey="productCode">{{ $t('product_code') }}</v-th>
                                <v-th sortKey="buyPrice">{{ $t('buy_price') }}</v-th>
                                <v-th sortKey="sellPrice">{{ $t('sell_price') }}</v-th>
                                <v-th sortKey="supplier">{{ $t('supplier') }}</v-th>
                                <th></th>
                            </thead>
                            <tbody slot="body" slot-scope="{displayData}">
                                <v-tr v-for="product in displayData" :key="product.productId" :row="product">
                                    <td>{{ product.name }}</td>
                                    <td>{{ product.productCode }}</td>
                                    <td>
                                        <ul class="bundle-list">
                                            <li class="bundle-product-badge" v-for="bundle in product.bundles" :key="bundle.id">{{ bundle.name }}</li>
                                        </ul>
                                    </td>
                                    <td><input type="number" :data-id="product.id" data-type="buyPrice" :value="product.buyPrice" v-debounce="updateProduct"></td>
                                    <td><input type="number" :data-id="product.id" data-type="sellPrice" :value="product.sellPrice" v-debounce="updateProduct"></td>
                                    <td>{{ product.supplier }}</td>
                                    <td>
                                      <a class="btn btn-danger m_fontSmall pull-right mls delete-link-btn mrs fontSmall show-modal" data-target="#modal-delete-product" @click="deleteProducts = [product]">x</a>
                                    </td>
                                </v-tr>
                            </tbody>
                        </v-table>
                        <p v-show="!productValue.length">{{ $t('no_data') }}</p>
                    </div>
                </div>
                <div class="panel_footer">
                </div>
            </div>
            <modal-shipping-method @shipping-changed="addProduct($event)" :selectedvalue="shipping.value" />
            <modal-bundles @bundles-added="addBundle($event)" :availableProducts="productValue" />
            <modal-add-product @available-products-counter="availableProductsToAdd = $event" @products-added="addProduct($event)" :selectedvalue="products" />
            <modal-edit-products ref="bulkedit" @products-edited="bulkEdit($event)" :selectedvalue="selectedRows" :options="productValue"/>
            <modal-delete-product @products-deleted="removeProduct($event)" :selectedvalue="deleteProducts" />
            <modal-delete-bundle @bundle-deleted="removeBundle($event)" :selectedvalue="deleteBundles" />
        </div>
</template>

<script>
import ModalShippingMethod from '../Modals/ShippingMethod'
import ModalAddProduct from '../Modals/AddProduct'
import ModalEditProducts from '../Modals/EditProducts'
import ModalDeleteProduct from '../Modals/DeleteProduct'
import ModalDeleteBundle from '../Modals/DeleteBundle'
import ModalBundles from '../Modals/Bundles'
export default {
      name: 'tabShop',
      components: {
            ModalShippingMethod,
            ModalAddProduct,
            ModalDeleteProduct,
            ModalDeleteBundle,
            ModalEditProducts,
            ModalBundles
      },
      data(){
            return {
                  customer_id: '',
                  shipping: {
                        value: []
                  },
                  bundles: [
                    {
                        id: 1, 
                        name: "test one", 
                        products: [
                            {id: 'a', name: 'product example'},
                            {id: 'b', name: 'product example 2'}
                        ]
                    }
                  ],
                  products: [],
                  availableProductsToAdd: 0,
                  deleteProducts: [],
                  deleteBundles: [],
                  loading_products: false,
                  loading_bundles: true,
                  selectedRows: [],
                  filters: {
                      all: {
                          value: '',
                          keys: ['name', 'productCode', 'supplier']
                      },
                  },
            }
      },
      computed: {
          shippingValue(){
              if(this.products.length){
                  return this.products.filter(item => item.name.toLowerCase().includes('shipping'))
              } else {
                  return []
              }
          },
          productValue() {
              if(this.products.length){
                return this.products.filter(item => !item.name.toLowerCase().includes('shipping'))
              } else {
                  return []
              }
          },
      },
      methods: {
            updateProduct( value, e){
                const id = e.target.dataset.id
                const type = e.target.dataset.type
                const index = this.products.findIndex(product => product.id == id)
                if( index != -1) {
                    let product = this.products[index]
                    product[type] = value
                    this.saveExtendedProduct([product])
                }
            },
            addProduct(items){
                let products = []
                items.forEach(item => {
                    products.push({
                        id: this.$uuid.v4(),
                        customerId: this.customer_id,
                        productId: item.id,
                        name: item.label,
                        productCode: item.ProductCode ? item.ProductCode : '',
                        buyPrice: 0,
                        sellPrice: 0,
                        supplier: null,
                        subscriptionStartDate: null,
                        subscriptionEndDate: null,
                        expectedReturnRate: null,
                        daysForContract: null
                    })
                });
                this.saveExtendedProduct(products)
            },
            removeProduct(){
                const ids = this.deleteProducts.map(product => product.productId);
                const payload = { opportunityProducts: ids}
                this.axios.delete(`/customers/${this.customer_id}/opportunity-products`, { data: payload}).then((response) => {
                        this.getExtendedProducts()
                })
            },
            removeBundle(id){
                const bundleId = this.deleteBundles[0].id
                if (this.deleteBundles[0]){
                    this.axios.delete(`/customers/${this.customer_id}/products-bundle/bundle-id/${bundleId}`).then((response) => {
                            this.getBundles()
                            this.getExtendedProducts()
                    })
                }
            },
            addBundle(bundle){
                let payload = {
                    name: bundle.name,
                    productsIds: [],
                }
                bundle.value.forEach(product => {
                    payload.productsIds.push(product.id)
                });
                this.saveBundles(payload)
            },
            getExtendedProducts(){
                    this.loading_products = true
                    this.axios.get(`/customers/${this.customer_id}/opportunity-products`).then((response) => {
                        const data = response.data.data
                        data.map(product => {
                            if(product.bundles) {
                                product.bundles = JSON.parse(product.bundles)
                                return product
                            }
                        });
                        this.products = data
                        this.$nextTick(() => {
                                this.selectionUpdate()
                                this.$refs.activetable.deselectAll()
                        })
                        this.loading_products = false
                    })
            },
            saveExtendedProduct(payload){
                const obj = {
                    opportunityProducts: payload
                }
                this.axios.post(`/customers/${this.customer_id}/opportunity-products`, obj).then((response) => {
                    this.getExtendedProducts()
                })
            },
            getBundles(){
                    this.loading_bundles = true
                    this.axios.get(`/customers/${this.customer_id}/products-bundle`).then((response) => { 
                        this.bundles = response.data
                        this.loading_bundles = false
                    })
            },
            saveBundles(payload){
                
                this.axios.post(`/customers/${this.customer_id}/products-bundle`, payload).then((response) => {
                    this.getBundles()
                    this.getExtendedProducts()
                })
            },
            selectionUpdate(value = []){
                this.selectedRows = value
                this.deleteProducts = value
            },
            dispatchAction (actionName) {
                switch (actionName) {
                    case 'Remove':
                        this.deleteProducts = this.selectedRows
                        break
                    case 'Edit':
                        this.$refs.bulkedit.selectedProduct(this.selectedRows)
                        break
                }
            },
            increaseBy(oldValue, value, operation){
                let output;
                switch (operation) {
                    case 1: //just set
                        output = parseFloat(value)
                        break;
                    case 2: //value
                        output = parseFloat(oldValue) + parseFloat(value)
                        break;
                    case 3: //percent
                        output = parseFloat(oldValue) * (100 + parseFloat(value))/100
                        break;

                    default:
                        break;
                }
                return output < 0 ? 0 : Math.round(output * 100)/100
            },
            bulkEdit(val) {
                let payload = []
                val.products.value.forEach(element => {
                    const obj = element
                    for(const property in val){
                        const item = val[property]
                        const changeMethod = item.changeMethod;
                        const value = item.value;
                        if(property === 'supplier' && value) {
                            obj[property] = value
                        } else if (changeMethod !== null && value !== "" && value !== null){
                            obj[property] = this.increaseBy(element[property], value, changeMethod.id)
                        }
                    }
                    payload.push(obj)
                });
                this.saveExtendedProduct(payload)
            }
      },
      mounted(){
          this.customer_id = document.getElementById('shop-settings').getAttribute('data-customerId')
          this.getExtendedProducts()
          this.getBundles()
      }
}
</script>

<style>
table .item-selected {
    background-color:#E9FCFC;
}
table .item-selected:hover {
    background-color:#dafafa!important;
}
.search {
    width: auto;
    display: inline-block;
    margin-right: 17px;
    border-radius: 100px;
}
.actions-wrapper {
    display: inline-block;
    padding: 1rem;
    border-radius: 100px;
    background-color: #f1f5f6;
    margin-right: 17px;
    transition: all 0.5s ease-out;
}
.actions-wrapper.disabled {
    opacity: 0.5;
}
.actions-wrapper a:first-child {
    margin: 0 1rem;
}
.bundle-products-list, .bundle-list {
    display: flex;
    margin-bottom: 0;
    padding: 0;
    flex-wrap: wrap;
}
.bundle-product-badge {
    padding: 3px 6px;
    margin: 2px;
    background-color: #F1F5F8;
    border-radius: 15px;
    display: inline-block;
}
</style>