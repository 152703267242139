const PL = {
    shipping_cost: 'Koszt dostawy',
    method: 'Metoda',
    cost_pounds: 'Koszt [£]',
    add_shipping_cost: 'Dodaj koszt dostawy',
    no_data: 'Brak danych',
    selected_products: 'Wybrane produkty',
    actions: 'Działania',
    edit: 'Edycja',
    delete: 'Usuń',
    add_product: 'Dodaj produkt',
    product: 'Produkt',
    product_code: 'Kod produktu',
    buy_price: 'Cena kupna',
    sell_price: 'Cena sprzedaży',
    supplier: 'Dostawca',
    save_changes: 'Zapisz zmiany',
    close: 'Zamknij',
    are_you_sure_delete_products: 'Czy jesteś pewny że chcesz usunąć poniższe produkty',
    this_cannot_be_undone: 'Ta operacja nie może zostać cofnięta',
    delete_product: 'Usuń produkt',
    cancel: 'Anuluj',
    bulk_products_editor: 'Edycja wielu produktów',
    selected_products_to_edit: 'Edycja wybranych produktów',
    no_change: 'Brak zmian',
    expected_return_rate: 'Spodziewana wartość zwrotu',
    number_days_for_contract: 'Liczba dni na kontrakt',
    add_shipping_method: 'Dodaj metodę dostawy',
    set_new: 'Ustaw nowy',
    increase_by_value: 'Zwiększ o wartość',
    increase_by_percent: 'Zwiększ o %',
    previously_was: 'wcześniej',
    add_bundles: 'Add bundle',
    bundle_name: 'Nazwa paczki',
    text: 'Wprowadź tekst',
    select_products: 'Wybierz produkty',
    are_you_sure_delete_bundles: 'Czy jesteś pewny że chcesz usunąć poniższe paczki',
    delete_bundle: 'Usuń paczkę',
    bundles: 'Paczki',
    products: 'Produkty'
}
  
export default PL