import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueCookies from 'vue-cookies'
import EN from './languages/en'
import PL from './languages/pl'

Vue.use(VueI18n)
Vue.use(VueCookies)

const messages = {
    EN,
    PL
}

const i18n = new VueI18n({
  locale: $cookies.get('lang'), // set locale
  messages, // set locale messages
})

export default i18n