<template>
  <div>
        <div class="modal fade" data-modal-modifier="modal_fullBleed" tabindex="-1" role="dialog" aria-labelledby="modal-shipping-method-label"
              id="modal-shipping-method" ref="modal-shipping-method" data-show="true">
              <div class="modal_container modal-xl modal-dialog" role="document">
                    <a class="modal_close" data-dismiss="modal" aria-label="Close">
                          <i class="glyphicon glyphicon-remove"></i>
                    </a>
                    <div class="modal-content">
                        <div class="modal_body">
                              <div
                                    class="panel m_widthDouble m_heightDouble m_paddingThick m_headerImportant m_footerImportant">
                                    <div class="panel_header">
                                          <h1 class="modal-title text-center" id="modal-shipping-method-label">{{ $t('add_shipping_method') }}</h1>
                                    </div>
                                    <div class="panel_body">
                                          <multiselect
                                                id="shipping-select"
                                                :value="value"
                                                :options="options"
                                                :multiple="true"
                                                track-by="id"
                                                label="label"
                                                :loading="loading"
                                                @input="selected"
                                                :hideSelected="false"
                                                :closeOnSelect="true"
                                                :preserveSearch="true"
                                                :internal-search="false"
                                                :limit="20"
                                                @search-change="search"
                                          ></multiselect>
                                    </div>
                                    <div class="panel_footer">
                                          <div class="panel_footerLeft">
                                                <div class="btnGroup_container">
                                                      <div class="btnGroup">
                                                            <a type="button" class="btn btn-primary" :class="{disabled:!value.length}" @click="save" :data-dismiss="value.length ? 'modal':''">{{ $t('save_changes') }}</a>
                                                            <a type="button" class="btn btn-info"
                                                                  data-dismiss="modal">{{ $t('close') }}</a>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>

                              </div>

                        </div>
                    </div>
              </div>
        </div>
  </div>
</template>

<script>
export default {
      name: 'shippingMethod',
      props: {
            selectedvalue: {
                  type: Array,
                  required: false,
            }
      },
      data(){
            return {
                  name: 'Shipping method',
                  value: [],
                  options: [],
                  loading: false,
            }
      },
      methods: {
            selected(value) {
                  this.value = value
            },
            save(){
                  if(this.value.length){
                        this.$emit('shipping-changed', this.value)
                  }
            },
            getShippings(query = '') {
                  let customer_id = document.getElementById('shop-settings').getAttribute('data-customerId')
                  let q = query ? '?q=' + query : ''
                  this.loading = true
                  this.axios.get(`/customers/${customer_id}/delivery-products${q}`).then((response) => {
                        this.options = response.data.data
                        this.loading = false
                        this.defaultState()
                  })
            },
            search(searchQuery) {
                  this.getShippings(searchQuery)
            },
            defaultState(){
                  if(this.selectedvalue.length) {
                        this.value = this.selectedvalue
                  } else if (this.options.length === 1) {
                        this.value = this.options
                  } else {
                        this.value = []
                  }
            }
      },
      mounted() {
            this.getShippings()
      }
}
</script>

<style>
#modal-shipping-method .panel_body {
      overflow: initial;
}
</style>