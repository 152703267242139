<template>
  <div>
        <div class="ajaxModalContainer_modal modal modal_fullBleed fade" data-modal-modifier="modal_fullBleed" tabindex="-1" role="dialog" aria-labelledby="modal-delete-bundle"
              id="modal-delete-bundle">
              <div class="modal_container modal-xl modal-dialog" role="document">
                    <a class="modal_close" data-dismiss="modal" :aria-label="$t('close')">
                          <i class="glyphicon glyphicon-remove"></i>
                    </a>
                    <div class="modal-content">
                        <div class="modal_body">
                              <div class="panel m_widthDouble m_heightDouble m_paddingThick m_headerImportant m_footerImportant">
                                    <div class="panel_body">
                                          <div class="panel_bodyLeft">
                                                <div class="col-xs-12 mtm">
                                                      <p class="fontXLarge">{{ $t('are_you_sure_delete_bundles') }}?
                                                            <ul>
                                                                  <li v-for="item in selectedvalue" :key="item.id">{{ item.name }}</li>
                                                            </ul>
                                                      </p>
                                                      <p class="fontXLarge cDanger">{{ $t('this_cannot_be_undone') }}.</p>
                                                </div>
                                          </div>
                                    </div>
                                    <div class="panel_footer">
                                          <div class="panel_footerLeft">
                                                <div class="btnGroup_container">
                                                      <div class="btnGroup">
                                                            <a type="button" style="background-color: #DC143C" class="btn btn-danger" @click="remove" data-dismiss="modal">{{ $t('delete_bundle') }}</a>
                                                            <a type="button" class="btn btn-info" data-dismiss="modal">{{ $t('cancel') }}</a>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                    </div>
              </div>
        </div>
  </div>
</template>

<script>
export default {
      name: 'deleteBundle',
      props: {
            selectedvalue: {
                  type: Array,
                  required: false,
            }
      },
      data(){
            return {
                  name: 'Delete bundle',
                  loading: false,

            }
      },
      methods: {
            remove() {
                  this.$emit('bundle-deleted', true)
            },
      },
      mounted() {}
}
</script>

<style>
#modal-delete-bundle .panel_body {
      overflow: initial;
}
#modal-delete-bundle .panel_body ul{
      list-style: circle;
      margin-left: 0;
      margin-top: 6px;
      margin-bottom: 0;
}
#modal-delete-bundle .panel_body ul li{
      font-size: 14px;
}
#modal-delete-bundle .panel_body ul li:not(:last-child){
      padding-bottom: 6px;
}
</style>