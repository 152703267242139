<template>
  <div>
        <div class="ajaxModalContainer_modal modal modal_fullBleed fade" data-modal-modifier="modal_fullBleed" tabindex="-1" role="dialog" aria-labelledby="modal-add-bundle-label"
              id="modal-add-bundle">
              <div class="modal_container modal-xl modal-dialog" role="document">
                    <a class="modal_close" data-dismiss="modal" aria-label="Close">
                          <i class="glyphicon glyphicon-remove"></i>
                    </a>
                    <div class="modal-content">
                        <div class="modal_body">
                              <div
                                    class="panel m_widthDouble m_heightDouble m_paddingThick m_headerImportant m_footerImportant">
                                    <div class="panel_header">
                                          <h1 class="modal-title text-center" id="modal-add-bundle-label">{{ $t('add_bundles') }}</h1>
                                    </div>
                                    <div class="panel_body">
                                          <div class="fontLarge">
                                                <div class="neonFieldText form-group isRequired"
                                                      name="name" classlabel="Single Line Text" id="bundle-name"
                                                      datakey="name" deleted="0" order="0">
                                                      <div class="neonField_content"><label for="bundle-name"
                                                                  class="neonField_label control-label">{{ $t('bundle_name') }}</label>
                                                            <div class="neonInput">
                                                                  <input type="text" id="bundle-name" :placeholder="$t('text')" class="form-control" v-model="bundle_name" />
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                          <multiselect
                                                id="bundle-select"
                                                :value="value"
                                                :options="availableProducts"
                                                :multiple="true"
                                                track-by="id"
                                                label="name"
                                                :loading="loading"
                                                @input="selected"
                                                :hideSelected="false"
                                                :closeOnSelect="false"
                                                :preserveSearch="true"
                                                :internal-search="false"
                                                :limit="20"
                                                :placeholder="$t('select_products')"
                                          ></multiselect>
                                    </div>
                                    <div class="panel_footer">
                                          <div class="panel_footerLeft">
                                                <div class="btnGroup_container">
                                                      <div class="btnGroup">
                                                            <a type="button" class="btn btn-primary" :class="{disabled:!isValid}" :data-dismiss="value.length ? 'modal':''" @click="save">{{ $t('save_changes') }}</a>
                                                            <a type="button" class="btn btn-info"
                                                                  data-dismiss="modal">{{ $t('close') }}</a>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>

                              </div>

                        </div>
                    </div>
              </div>
        </div>
  </div>
</template>

<script>
export default {
      name: 'addBundle',
      props: {
            selectedvalue: {
                  type: Array,
                  required: false,
            },
            availableProducts: {
                  type: Array,
                  required: false,
            }
      },
      data(){
            return {
                  loading: false,
                  bundle_name: '',
                  value: [],
                  options: []
            }
      },
      computed: {
            filteredOptions(){
                  const allProducts = this.options;
                  const filteredProducts = allProducts.filter(item => {
                        const index = this.selectedvalue.findIndex(selectedItem =>
                              selectedItem.productId == item.id
                        )
                        if(index === -1){
                              return item
                        }
                  })
                  this.$emit('available-products-counter', filteredProducts.length)
                  return filteredProducts
            },
            isValid(){
                  return this.bundle_name.trim().length && this.value.length
            }
      },
      methods: {
            selected(value) {
                  this.value = value
            },
            save(){
                  if(this.isValid){
                        this.$emit('bundles-added', {name: this.bundle_name , value: this.value})
                  }
                  this.clearValue()
            },
            getProducts(query = '') {
                  let customer_id = document.getElementById('shop-settings').getAttribute('data-customerId')
                  let q = query ? '?q=' + query : ''
                  this.loading = true
                  this.axios.get(`/customers/${customer_id}/products${q}`).then((response) => {
                        this.options = response.data
                        this.loading = false
                  })
            },
            search(searchQuery) {
                  this.getProducts(searchQuery)
            },
            clearValue(){
                  setTimeout(()=>{
                        this.value = []
                        this.bundle_name = ''
                  }, 1000)
            }
      },
      mounted() {
            this.getProducts()
      },
}
</script>

<style>
#modal-add-bundle .panel_body {
      overflow: initial;
}
#modal-add-bundle input#bundle-name {
      border: 1px solid #e8e8e8;
      min-height: 40px;
}
</style>