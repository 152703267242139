<template>
  <div>
        <div class="ajaxModalContainer_modal modal modal_fullBleed fade" data-modal-modifier="modal_fullBleed" tabindex="-1" role="dialog" aria-labelledby="modal-edit-products-label"
              id="modal-edit-products">
              <div class="modal_container modal-xl modal-dialog" role="document">
                    <a class="modal_close" data-dismiss="modal" aria-label="Close">
                          <i class="glyphicon glyphicon-remove"></i>
                    </a>
                    <div class="modal-content">
                        <div class="modal_body">
                              <div
                                    class="panel m_footerImportant">
                                    <div class="panel_header">
                                          <h1 class="modal-title text-center" id="modal-edit-products-label">{{ $t('bulk_products_editor') }}</h1>
                                    </div>
                                    <div class="panel_body container">
                                          <div class="form-group">
                                                <label for="edit-product-select">{{ $t('selected_products_to_edit') }} <sup>({{formValues.products.value.length}})</sup></label>
                                                <multiselect
                                                id="edit-product-select"
                                                :value="formValues.products.value"
                                                :options="filteredOptions"
                                                :multiple="true"
                                                track-by="id"
                                                label="name"
                                                @input="selectedProduct"
                                                :hideSelected="false"
                                                :closeOnSelect="false"
                                                :preserveSearch="true"
                                                :internal-search="false"
                                                :limit="20"
                                                @search-change="findProduct"
                                                ></multiselect>
                                          </div>
                                                <div class="row">
                                                      <div class="col-sm-12 col-md-6">
                                                            <label for="buyPrice">{{ $t('buy_price') }}</label>
                                                            <div class="form-group flex">
                                                                  <multiselect
                                                                        id="buyPrice"
                                                                        v-model="formValues.buyPrice.changeMethod"
                                                                        :options="selectOptions"
                                                                        track-by="id"
                                                                        label="name"
                                                                        :placeholder="'-- ' + $t('no_change') + ' --'"
                                                                        :searchable="false"
                                                                        :showLabels="false"
                                                                        :class="{'w-50': formValues.buyPrice.changeMethod}"
                                                                        @select="changeMethod"
                                                                  ></multiselect>
                                                                  <input v-show="formValues.buyPrice.changeMethod" type="number" step="0.01" ref="buyPriceValue" :placeholder="setPlaceholder('buyPrice')" class="multiselect__tags w-50" v-model="formValues.buyPrice.value">
                                                            </div>
                                                            <label for="sellPrice">{{ $t('sell_price') }}</label>
                                                            <div class="form-group flex">
                                                                  <multiselect
                                                                        id="sellPrice"
                                                                        v-model="formValues.sellPrice.changeMethod"
                                                                        :options="selectOptions"
                                                                        track-by="id"
                                                                        label="name"
                                                                        :placeholder="'-- ' + $t('no_change') + ' --'"
                                                                        :searchable="false"
                                                                        :showLabels="false"
                                                                        :class="{'w-50': formValues.sellPrice.changeMethod}"
                                                                        @select="changeMethod"
                                                                  ></multiselect>
                                                                  <input v-show="formValues.sellPrice.changeMethod" type="number" step="0.01" ref="sellPriceValue" :placeholder="setPlaceholder('sellPrice')" class="multiselect__tags w-50" v-model="formValues.sellPrice.value">
                                                            </div>
                                                            <label for="supplier">{{ $t('supplier') }}</label>
                                                            <div class="form-group flex">
                                                                  <multiselect
                                                                        id="supplier"
                                                                        v-model="formValues.supplier.value"
                                                                        :options="suppliers"
                                                                        :placeholder="setPlaceholder('supplier')"
                                                                        :searchable="true"
                                                                        :options-limit="300"
                                                                        :allow-empty="true"
                                                                  ></multiselect>
                                                            </div>
                                                      </div>
                                                      <div class="col-sm-12 col-md-6">
                                                            <!--
                                                                  <label for="sub_start_date">Subsription Start Date</label>
                                                                  <div class="form-group">
                                                                  <date-picker
                                                                        id="sub_start_date"
                                                                        ref="sub_start_date"
                                                                        :input-props="dateProps"
                                                                        :masks='{ input: "DD/MM/YYYY"}'
                                                                        :update-on-input="true"
                                                                        v-model='formValues.subscriptionStartDate'
                                                                        color="teal"
                                                                        class="date-picker"
                                                                        >
                                                                        <template v-slot="{ inputValue, inputEvents }">
                                                                              <input
                                                                              class="multiselect__tags"
                                                                              :value="inputValue"
                                                                              v-on="inputEvents"
                                                                              />
                                                                        </template>
                                                                  </date-picker>
                                                            </div>
                                                            <label for="sub_end_date">Subsription End Date</label>
                                                            <div class="form-group">
                                                                  <date-picker
                                                                        id="sub_end_date"
                                                                        ref="sub_end_date"
                                                                        :input-props="dateProps"
                                                                        :masks='{ input: "DD/MM/YYYY"}'
                                                                        :update-on-input="true"
                                                                        v-model='formValues.subscriptionEndDate'
                                                                        color="teal"
                                                                        class="date-picker"
                                                                        >
                                                                        <template v-slot="{ inputValue, inputEvents }">
                                                                              <input
                                                                              class="multiselect__tags"
                                                                              :value="inputValue"
                                                                              v-on="inputEvents"
                                                                              />
                                                                        </template>
                                                                  </date-picker>
                                                            </div> -->
                                                            <label for="returnRate">{{ $t('expected_return_rate') }}</label>
                                                            <div class="form-group flex">
                                                                  <multiselect
                                                                        id="returnRate"
                                                                        v-model="formValues.returnRate.changeMethod"
                                                                        :options="selectOptions"
                                                                        track-by="id"
                                                                        label="name"
                                                                        :placeholder="'-- ' + $t('no_change') + ' --'"
                                                                        :searchable="false"
                                                                        :showLabels="false"
                                                                        :class="{'w-50': formValues.returnRate.changeMethod}"
                                                                        @select="changeMethod"
                                                                  ></multiselect>
                                                                  <input v-show="formValues.returnRate.changeMethod" type="number" step="0.01" ref="returnRateValue" :placeholder="setPlaceholder('returnRate')" class="multiselect__tags w-50" v-model="formValues.returnRate.value">
                                                            </div>
                                                            <label for="daysOfContract">{{ $t('number_days_for_contract') }}</label>
                                                            <div class="form-group flex">
                                                                  <multiselect
                                                                        id="daysOfContract"
                                                                        v-model="formValues.daysOfContract.changeMethod"
                                                                        :options="selectOptions"
                                                                        track-by="id"
                                                                        label="name"
                                                                        :placeholder="'-- ' + $t('no_change') + ' --'"
                                                                        :searchable="false"
                                                                        :showLabels="false"
                                                                        :class="{'w-50': formValues.daysOfContract.changeMethod}"
                                                                        @select="changeMethod"
                                                                  ></multiselect>
                                                                  <input v-show="formValues.daysOfContract.changeMethod" type="number" step="0.01" ref="daysOfContractValue" :placeholder="setPlaceholder('daysOfContract')" class="multiselect__tags w-50" v-model="formValues.daysOfContract.value">
                                                            </div>
                                                      </div>
                                                </div>
                                    </div>
                                    <div class="panel_footer">
                                          <div class="panel_footerLeft">
                                                <div class="btnGroup_container">
                                                      <div class="btnGroup">
                                                            <a type="button" class="btn btn-primary" :class="{disabled:!isValid}" :data-dismiss="isValid ? 'modal':''" @click="save">{{ $t('save_changes') }}</a>
                                                            <a type="button" class="btn btn-info"
                                                                  data-dismiss="modal">{{ $t('close') }}</a>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>

                              </div>

                        </div>
                    </div>
              </div>
        </div>
  </div>
</template>

<script>
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
export default {
      components: {
            DatePicker
      },
      name: 'EditProduct',
      props: {
            selectedvalue: {
                  type: Array,
                  required: false,
            },
            options: {
                  type: Array,
                  required: false,
            }
      },
      data(){
            return {
                  name: 'Edit products',
                  loading: false,
                  formValues: {
                        products: {changeMethod: null, value:[]},
                        buyPrice: {changeMethod: null, value:''},
                        sellPrice: {changeMethod: null, value:''},
                        supplier: {changeMethod: null, value:''},
                        // subscriptionStartDate: '',
                        // subscriptionEndDate: '',
                        returnRate: {changeMethod: null, value:''},
                        daysOfContract: {changeMethod: null, value:''},
                  },
                  filteredOptions: [],
                  selectOptions: [
                        {id:1, name: this.$t('set_new'), value: 'newValue'},
                        {id:2, name: this.$t('increase_by_value'), value: 'increaseValue'},
                        {id:3, name: this.$t('increase_by_percent'), value: 'increaseProcent'},
                  ],
                  dateProps: {
                        placeholder: "DD/MM/YYYY",
                        disabled: false,
                  },
                  suppliers: []
            }
      },
      watch: {
      	selectedvalue: function(newVal, oldVal) { // watch it
                  if(newVal !== oldVal) this.selectedProduct(newVal)
        }
      },
      computed: {
            isValid() {
                  const products = this.formValues.products.value.length,
                        buyPrice = this.formValues.buyPrice.changeMethod && this.formValues.buyPrice.value ? 1 : 0,
                        sellPrice = this.formValues.sellPrice.changeMethod && this.formValues.sellPrice.value ? 1 : 0,
                        supplier = this.formValues.supplier.value,
                        returnRate = this.formValues.returnRate.changeMethod && this.formValues.returnRate.value ? 1 : 0,
                        daysOfContract = this.formValues.daysOfContract.changeMethod && this.formValues.daysOfContract.value ? 1 : 0;
                  if(products && (buyPrice || sellPrice || supplier || returnRate || daysOfContract)){
                        return true
                  } else { return false}
            }
      },
      methods: {
            selectedProduct(value) {
                  this.formValues.products.value = value
            },
            save(){
                  if(this.isValid){
                        this.$emit('products-edited', this.formValues)
                        this.clearValue()
                  }
            },
            clearValue(){
                  setTimeout(()=>{
                        this.formValues = {
                        products: {changeMethod: null, value:[]},
                        buyPrice: {changeMethod: null, value:''},
                        sellPrice: {changeMethod: null, value:''},
                        supplier: {changeMethod: null, value:''},
                        // subscriptionStartDate: '',
                        // subscriptionEndDate: '',
                        returnRate: {changeMethod: null, value:''},
                        daysOfContract: {changeMethod: null, value:''},
                  }
                  }, 500)
            },
            findProduct(query){
                  if(query.length < 1) {
                        this.filteredOptions = this.options
                  } else {
                        let array1 = this.options.filter(option => option.name.toLowerCase().includes(query.toLowerCase()))
                        let array2 = this.options.filter(option => option.productCode.toLowerCase().includes(query.toLowerCase()));
                        let array3 = this.options.filter(option => option.productId.toLowerCase().includes(query.toLowerCase()));
                        let concatArray = array1.concat(array2, array3);
                        concatArray = [...new Set([...array1,...array2,...array3])];
                        this.filteredOptions = concatArray;
                  }
            },
            changeMethod(selectedOption, id){
                  if(selectedOption){
                        setTimeout(()=>{
                              this.$refs[id + 'Value'].focus()
                        },0)
                  }
            },
            getSuppliers(query = ''){
                let q = query ? '?q=' + query : ''
                this.axios.get(`/suppliers${q}`).then((response) => {
                      this.suppliers = response.data
                      this.loading = false
                    })
            },
            setPlaceholder(type){
                  if(this.formValues.products.value.length === 1) {
                        switch (type) {
                              case 'buyPrice':
                                    return this.$t('previously_was') + ' "' + this.formValues.products.value[0].buyPrice + '"'
                              case 'sellPrice':
                                    return this.$t('previously_was') + ' "' + this.formValues.products.value[0].sellPrice + '"'
                              case 'daysOfContract':
                                    return this.$t('previously_was') + ' "' + this.formValues.products.value[0].daysForContract + '"'
                              case 'returnRate':
                                    return this.$t('previously_was') + ' "' + this.formValues.products.value[0].expectedReturnRate + '"'
                              case 'supplier':
                                    return this.$t('previously_was') + ' "' + this.formValues.products.value[0].supplier + '"'
                              default:
                                    return ''
                        }
                  } else { return '-- ' + this.$t('no_change') + ' --'}
            }
      },
      mounted() {
            this.filteredOptions = this.options
            this.getSuppliers()
      },
}
</script>

<style scoped>
#modal-edit-products .panel_body {
      overflow: initial;
      margin-bottom: 2rem;
}
#modal-edit-products label {
      font-weight: 300;
      display: block;
}
.date-picker .multiselect__tags {
      width: 100%;
      padding: 0 8px;
}
.form-group.flex {
      display: flex;
}
.w-50 {
      width: 50%;
      display: inline-block;
}
input.w-50 {
      padding: 0 8px;
      font-size: 16px;
      color: #35495e;
      outline: none;
}
.form-group.flex .w-50:first-child {
      margin-right: 7.5px;
}
.form-group.flex .w-50:last-child {
      margin-left: 7.5px;
}
</style>