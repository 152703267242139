import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

const host = location.hostname;
let app_env;

if (host.includes('localhost')) {
  app_env = process.env.VUE_APP_SALESFORCE_DEV_API_URL
} else if (host.includes('test.hub.symec')) {
  app_env = process.env.VUE_APP_SALESFORCE_TEST_API_URL
} else {
  app_env = process.env.VUE_APP_SALESFORCE_LIVE_API_URL
}

axios.defaults.baseURL = app_env;

import App from './App.vue'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import UUID from "vue-uuid"
Vue.use(UUID)
import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce, {
  listenTo: 'input',
  defaultTime: '700ms'
})
import SmartTable from 'vuejs-smart-table'
Vue.use(SmartTable)

import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
  locales: {
    masks: {
      input: ["DD-MM-YYYY HH:mm", "DD/MM/YYYY HH:mm"],
      data: ["DD-MM-YYYY HH:mm", "DD/MM/YYYY HH:mm"]
    }
  },
  masks: {
    input: ["DD-MM-YYYY HH:mm", "DD/MM/YYYY HH:mm"],
    data: ["DD-MM-YYYY HH:mm", "DD/MM/YYYY HH:mm"]
  }
});

import i18n from './translation'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

Vue.component('multiselect', Multiselect)

Vue.config.productionTip = false

class ShopSettings extends HTMLElement {
  connectedCallback() {
    this.innerHTML = "<div id='shop-settings-app'></div>"
    new Vue({
      i18n,
      render: h => h(App),
    }).$mount('#shop-settings-app')
  }
}
customElements.define("app-shop-settings", ShopSettings);
